<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid" style="color: grey">
        <!--begin::Portlet-->
        <div class="kt-portlet">
            <div class="kt-portlet__head no-border-radius">
                <div class="kt-portlet__head-label">
                </div>
                <div class="kt-portlet__head-toolbar">
                    <app-button type="secondary" @handleClick="back">
                        <i class="glyphicons glyphicons-arrow-left"></i>{{ $t('back') }}
                    </app-button>
                </div>
            </div>
            <div class="mx-4 mt-4 row">
                <div class="col-xl-4 col-lg-12">
                    <div class="shadow-lg bg-white" style="border-radius: 10px; height: 30rem">
                        <Skeleton v-show="loading" width="100%" height="100%" borderRadius="10px"/>
                        <div v-show="!loading">
                            <div class="d-flex flex-column justify-content-center align-items-center mx-xl-4 mx-3">
                                <div style="font-size: 1.5rem" class="pt-4">
                                    <div v-if="vehicle.identification">
                                        {{ vehicle.identification }}
                                    </div>
                                    <div v-else>
                                        N/D
                                    </div>
                                </div>
                                <img style="max-height: 280px" alt="modelo" class="img-fluid" :src=modelImage
                                     @load="onImageLoad"
                                     onerror="this.src = 'https://static.iotag.com.br/vehicles/vehicle_512.png'">
                                <div style="font-size: 1.4rem" class="pb-5">
                                    <div v-if="vehicleStatus">
                                        {{ vehicleStatus.name }}
                                        <span class="pi pi-question-circle help-style" v-if="vehicleStatus.info"
                                              v-tooltip="vehicleStatus.info"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12">
                    <div class="mt-xl-0 mt-4">
                        <div class="shadow-lg bg-white info-card-height" style="border-radius: 10px">
                            <Skeleton v-if="loading" width="100%" height="100%" borderRadius="10px"/>
                            <div v-else>
                                <app-vehicle-info-general :vehicle="vehicle" :models="models"
                                                          :manufacturers="manufacturers" :vehicle-model="vehicleModel"
                                                          :vehicle-manufacturer="vehicleManufacturer"
                                                          @getModels="getModels" @saveVehicle="saveVehicle"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-12">
                    <div class="shadow-lg bg-white small-card-height mb-4 mt-xl-0 mt-4" style="border-radius: 10px">
                        <Skeleton v-if="loading" width="100%" height="100%" borderRadius="10px"/>
                        <div v-else>
                            <app-vehicle-info-last-contact :vehicle="vehicle"/>
                        </div>
                    </div>
                    <div class="shadow-lg bg-white small-card-height mb-4" style="border-radius: 10px">
                        <Skeleton v-if="loading" width="100%" height="100%" borderRadius="10px"/>
                        <div v-else>
                            <app-vehicle-info-activity :vehicle-activity="vehicleActivity" :activities="activities"
                                                       @saveActivity="saveActivity"/>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <app-button type="primary" style="background-color: grey" class="mr-2 p-0 button shadow-lg"
                                    @handleClick="handleAvailability">
                            {{ vehicleAvailable ? 'Tirar de operação' : 'Colocar em operação' }}
                        </app-button>
                        <app-button type="danger" class="ml-2 button shadow-lg"
                                    @handleClick="handleRemove">
                            Remover
                        </app-button>
                    </div>
                </div>
            </div>
        </div>

        <Dialog :visible.sync="showConfirmDeleteDialog" :modal="true"
                :contentStyle="{overflow: 'visible', width: '450px'}">
            <template #header>
                <div style="font-size: larger; font-weight: bold" class="pl-3">
                    <span>Tem certeza que deseja remover o veículo?</span>
                </div>
            </template>
            <div class="pl-3"/>
            <template #footer>
                <Button label="Não" @click="showConfirmDeleteDialog = false" class="p-button-secondary"/>
                <Button label="Sim" @click="removeVehicle" class="p-button-info"/>
            </template>
        </Dialog>

    </div>
</template>

<script>

import {mapGetters} from 'vuex';
import {router} from '@/router'

import AppVehiclesForm from "./AppVehiclesForm";
import AppButton from "../../common/AppButton";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Skeleton from "primevue/skeleton";
import Tooltip from "primevue/tooltip";

import VehiclesService from "@/services/VehiclesService";
import MissionService from "@/services/MissionService";
import VehiclesManufacturersService from "@/services/VehiclesManufacturersService";
import ActivitiesService from "@/services/ActivitiesService";
import AppVehicleInfoLastContact from "@/components/views/vehicles/AppVehicleInfoLastContact";
import AppVehicleInfoActivity from "@/components/views/vehicles/AppVehicleInfoActivity";
import AppVehicleInfoGeneral from "@/components/views/vehicles/AppVehicleInfoGeneral";

export default {
    mounted() {
        this.vehiclesService = new VehiclesService();
        this.missionService = new MissionService();
        this.vehiclesManufacturersService = new VehiclesManufacturersService();
        this.activitiesService = new ActivitiesService();

        if (!this.$route.params.id) {
            this.exitPageOnError()
            return
        }

        this.loading = true;
        this.getManufacturers();
    },
    data() {
        return {
            loading: false,
            vehiclesService: null,
            missionService: null,
            vehiclesManufacturersService: null,
            activitiesService: null,
            vehicleId: this.$route.params.id,
            vehicle: {},
            vehicleManufacturer: null,
            vehicleModel: null,
            modelImage: null,
            isNewImage: false,
            manufacturers: [],
            models: [],
            vehicleAvailable: true,
            vehicleActivity: {
                activity: null,
                workingWidth: null
            },
            activities: [],
            showConfirmDeleteDialog: false,
            vehicleStatus: null,
            vehicleStatuses: {
                outOfOperation: {
                    name: 'Fora de operação'
                },
                active: {
                    name: 'Em operação - ativo',
                    info: 'Conectado nos útlimos três dias'
                },
                inactive: {
                    name: 'Em operação - inativo',
                    info: 'Sem conexão por mais de três dias'
                },
                disconnected: {
                    name: 'Desconectado',
                    info: 'Sem dispositivo no veículo'
                }
            }
        }
    },
    components: {
        AppVehicleInfoGeneral,
        AppVehicleInfoActivity,
        AppVehicleInfoLastContact,
        AppVehiclesForm,
        AppButton,
        InputText,
        Dropdown,
        Dialog,
        Button,
        Skeleton,
        Tooltip
    },
    directives: {
        'tooltip': Tooltip
    },
    methods: {
        getManufacturers() {
            this.vehiclesManufacturersService.getManufacturers()
                .then((response) => {
                    this.manufacturers = response.sort((a, b) => a.name.localeCompare(b.name))
                    this.getVehicleDetails();
                }).catch(() => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Não foi possivel recuperar a lista de fabricantes',
                    life: 5000
                });
            })
        },
        getVehicleDetails() {
            this.vehiclesService.getVehicleDetails(this.vehicleId)
                .then((response) => {
                    this.vehicle = response;
                    this.vehicleAvailable = !response.unavailability_date
                    this.setVehicleStatus();
                    this.setManufacturer();
                }).catch(() => {
                this.exitPageOnError()
            })
        },
        setVehicleStatus() {
            if (!this.vehicle.mac) {
                this.vehicleStatus = this.vehicleStatuses.disconnected
                return
            }

            if (!this.vehicleAvailable) {
                this.vehicleStatus = this.vehicleStatuses.outOfOperation
                return
            }

            if (this.isVehicleActive()) {
                this.vehicleStatus = this.vehicleStatuses.active
                return
            }

            this.vehicleStatus = this.vehicleStatuses.inactive
        },
        isVehicleActive() {
            let MAX_HOURS_NO_CONTACT = (3 * 24 * 3600)
            let noContact = Math.floor(Date.now() / 1000) - this.vehicle.last_contact
            return noContact <= MAX_HOURS_NO_CONTACT
        },
        setManufacturer() {
            let manufacturerFoundInList = this.manufacturers.find(obj => obj.id === this.vehicle.manufacturer_id)

            if (!manufacturerFoundInList) {
                this.modelImage = 'https://static.iotag.com.br/vehicles/vehicle_512.png'
                this.getActivities()
                return
            }

            this.vehicleManufacturer = manufacturerFoundInList
            this.getModels(this.vehicleManufacturer.id, false)
        },
        getModels(manufacturerId, isEditing) {
            this.vehiclesService.getModelsByManufacturer(manufacturerId)
                .then((response) => {
                    this.models = response.models.sort((a, b) => a.name.localeCompare(b.name))
                    if (isEditing) {
                        return
                    }
                    this.setVehicleModel()
                }).catch(() => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Não foi possivel recuperar a lista de modelos',
                    life: 5000
                });
            })
        },
        setVehicleModel() {
             let modelFoundInList = this.models.find(obj => obj.id === this.vehicle.model_id)

            if (!modelFoundInList) {
                this.modelImage = 'https://static.iotag.com.br/vehicles/vehicle_512.png'
                this.getActivities()
                return
            }

            this.vehicleModel = modelFoundInList
            this.setModelImage()
            this.getActivities()
        },
        setModelImage() {
            let newImageSrc = `https://static.iotag.com.br/vehicles/${this.vehicleModel.id}.png`
            this.isNewImage = newImageSrc !== this.modelImage
            this.modelImage = newImageSrc
        },
        getActivities() {
            this.activitiesService.getActivitiesByOrganization(this.vehicle.organization_id)
                .then((response) => {
                    this.activities = response.sort((a, b) => a.description.localeCompare(b.description))
                    this.getVehicleMission()
                }).catch(() => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Não foi possivel recuperar a lista de atividades',
                    life: 5000
                });
            })
        },
        getVehicleMission() {
            this.missionService.getCurrentMission(this.vehicleId)
                .then((response) => {
                    this.vehicleActivity.activity = response._048
                    this.vehicleActivity.workingWidth = response._055
                    this.setVehicleActivity()
                }).catch(() => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Não foi possivel recuperar as informações da atividade',
                    life: 5000
                });
            }).finally(() => {
                if (!this.isNewImage) {
                    this.loading = false
                }
            })
        },
        setVehicleActivity() {
            this.vehicleActivity.activity = this.activities.find(obj => obj.identification === this.vehicleActivity.activity)
        },
        saveVehicle(data) {
            this.loading = true
            this.vehiclesService.patchV3(this.vehicleId, data)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Veículo atualizado com sucesso',
                        life: 5000
                    });
                    this.getVehicleDetails()
                }).catch(() => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Não foi possível atualizar o veículo',
                    life: 5000
                });
            })
        },
        saveActivity(data) {
            this.loading = true
            this.isNewImage = false
            this.missionService.setCurrentMission(this.vehicleId, data)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Atividade atualizada com sucesso',
                        life: 5000
                    });
                    this.getVehicleMission()
                }).catch(() => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Não foi possível salvar a atividade',
                    life: 5000
                });
            })
        },
        exitPageOnError() {
            this.$toast.add({
                severity: 'error',
                summary: 'Não foi possivel recuperar as informações do veículo',
                life: 5000
            });
            this.back()
        },
        back() {
            router.go(-1);
        },
        handleAvailability() {
            this.loading = true
            if (this.vehicleAvailable) {
                this.makeVehicleUnavailable()
                return
            }

            this.makeVehicleAvailable()
        },
        makeVehicleUnavailable() {
            this.vehiclesService.makeUnavailable(this.vehicleId)
                .then(() => {
                    this.vehicleAvailable = false
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Veículo configurado como \'Fora de operação\'',
                        life: 5000
                    });
                    this.getVehicleDetails()
                }).catch(() => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Não foi possível configurar o veículo como \'Fora de operação\'',
                    life: 5000
                });
            })
        },
        makeVehicleAvailable() {
            this.vehiclesService.makeAvailable(this.vehicleId)
                .then(() => {
                    this.vehicleAvailable = true
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Veículo configurado como \'Em operação\'',
                        life: 5000
                    });
                    this.getVehicleDetails()
                }).catch(() => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Não foi possível configurar o veículo como \'Em operação\'',
                    life: 5000
                });
            })
        },
        handleRemove() {
            this.showConfirmDeleteDialog = true
        },
        removeVehicle() {
            this.vehiclesService.removeVehicle(this.vehicleId)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Veículo removido com sucesso',
                        life: 5000
                    });
                    this.back()
                }).catch(() => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Não foi possível remover o veículo, tente novamente mais tarde',
                    life: 5000
                });
            }).finally(() => {
                this.showConfirmDeleteDialog = false
            })
        },
        onImageLoad() {
            this.loading = false
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentOrganization'
        ])
    }
}
</script>

<style scoped>

.button {
    border-radius: 8px;
    width: 50%;
    height: 4rem;
    font-size: larger;
}

.info-card-height {
    height: 30rem;
}

.small-card-height {
    height: 11.5rem;
}

@media (max-width: 576px) {
    .info-card-height {
        height: 48rem !important;
    }

    .small-card-height {
        height: 17rem !important;
    }
}

</style>
