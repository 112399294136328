<template>
    <div style="margin-top: 0px;width: 100%;overflow: hidden" id="tableDiv"
         v-if="events.length > 0">
        <DataTable :value="selectedEvents" :paginator="true" :rows="10" :rowHover="true"
                   :sortOrder="-1" sortField="last_modified_date"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   :rowsPerPageOptions="[10,25,50]"
                   currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} Eventos"
                   class="p-datatable-striped"
                   :autoLayout="true" :style="{width: setDialogWidth, paddingTop: '0px', fontSize: '14px'}"
                   :scrollable="true" :filters.sync="filters"
                   ref="report" removableSort  selectionMode="single" filterDisplay="row">
            <template #empty>
                Nenhum evento localizado
            </template>

            <template #header>
                <div style="text-align: right">
                    <Button icon="pi pi-external-link" label="Exportar" @click="exportCsvReport($event)"/>
                </div>
            </template>

            <Column :headerStyle="{maxWidth: '40px !important', overflow: 'hidden'}"
                    :style="{maxWidth: '40px !important',overflow: 'hidden'}" :filterHeaderStyle="{maxWidth: '40px !important'}"
                    :bodyStyle="{maxWidth: '40px !important', backgroundColor: 'transparent', textAlign: 'center'}">
                <template #header>
                    <Button icon="pi pi-filter-slash" @click="clearFilters"/>
                </template>
                <template #body="slotProps">
                    <Button :icon="showMoreButtonIcon" class="p-button-rounded p-button-success p-button-outlined p-0"
                            style="height: 25px;width: 25px; position: relative; right: 6px;" @click="openDialog(slotProps.data)"/>
                </template>
            </Column>

            <Column v-for="(col, index) of columns" :field="col.field" :key="col.field + '_' + index"
                    :headerStyle="{width: '150px !important', backgroundColor: setColumnBackgroundColor(index)}"
                    :showFilterMenu="false" :showClearButton="false" :filterHeaderStyle="{width: '150px !important'}"
                    :sortable="false" :bodyStyle="{width: '150px !important', backgroundColor: setColumnBackgroundColor(index)}"
                    filterMatchMode="contains">
                <template #header>
                    <div style="width: 142px;">
                        <span>{{col.header}}</span>
                        <MultiSelect :value="selectedColumns[index].options"
                                     style="float: right;display: flex;align-items: center;justify-content: center;margin-top:4px;
                                        margin-left:3px;"
                                     :options="columns[index].options"
                                     optionLabel="option"
                                     appendTo="tableDiv"
                                     @input="multiSelectInputMethod(index, $event)"
                                     id="table-multiselect"
                                     :disabled=groupIcons[index].disabled :filter="true">
                        </MultiSelect>
                        <Button :icon=groupIcons[index].icon :class=groupIcons[index].class
                                style="float: right;height: 17px;width: 20px;display: flex;
                                            align-items: center;justify-content: center;
                                            margin-left:4px;"
                                :disabled=groupIcons[index].disabled
                                @click="groupButtonClicked(index)"/>
                    </div>
                </template>
                <template #body="slotProps">
                    <div v-tooltip="displayTooltip(slotProps.data[col.field])" style="position: relative; right: 7px;">
                        {{hideValuesGreaterThanMaximumDisplay(slotProps.data[col.field])}}
                    </div>
                </template>
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" placeholder="Filtrar" @input="filterCallback()" class="p-column-filter"/>
                </template>
            </Column>


            <Column v-for="(col, index) of selectedStatisticsColumns" :headerStyle="{width: '150px !important'}"
                    :field="col.field" :bodyStyle="{position: 'relative', right: '10px', width: '150px !important'}"
                    :key="col.field + '_' + index" sortable>

                <template #header>
                    <div style="width: 113px;">
                        <span>{{col.header}}</span>
                        <MultiSelect :value="selectedStatisticsColumns"
                                    style="float: right;display: flex;align-items: center;justify-content: center;margin-top:4px;
                                        margin-left:3px;"
                                    :options="statisticsColumns"
                                    optionLabel="header"
                                    @input="onStatisticsToggle"
                                    id="columns-multiselect"
                                    appendTo="tableDiv"
                                    :filter="false">
                        </MultiSelect>
                    </div>

                </template>

                <template #body="slotProps">
                    <div v-if="col.displayOption === 'percentage'" style="width: 100%;">
                        <span class="p-column-title" v-if="slotProps.data[col.field] === 0"></span>
                        <ProgressBar v-else :value="slotProps.data[col.field]" :showValue="true">
                            {{displayStatistics(slotProps.data[col.field])}}%
                        </ProgressBar>
                    </div>

                    <div v-if="col.displayOption === 'timestampSeconds'">
                        {{getTimeFromSecondsMinusThreeHoursMixin(slotProps.data[col.field])}}
                    </div>
                    <div v-if="col.displayOption === 'durationSeconds'">
                        {{getTimeFromSecondsDurationFormatMixin(slotProps.data[col.field])}}
                    </div>
                    <div v-if="col.displayOption === 'durationHours'">
                        {{getTimeFromHoursDurationFormatMixin(slotProps.data[col.field])}}
                    </div>
                    <div v-if=" col.displayOption === 'number'">
                        {{displayStatistics(slotProps.data[col.field])}}
                    </div>
                </template>
            </Column>
        </DataTable>

        <app-analysis-summary-dialog :dialog_visible="dialogVisible" @closeDialog="setDisplayVisibleToFalse"
                                     :raw_events="events" :selected_columns="selectedColumns" :selected_events="selectedEvents" :grouped_row_clicked="groupedRowClicked"
                                     :raw_columns="columns" :selected_statistics_columns="selectedStatisticsColumns" :group_checked="groupChecked"
                                     :group_on="groupOn">
        </app-analysis-summary-dialog>

    </div>
</template>

<script>

import AppDateTimeControlBar from "../../../common/AppDateTimeControlBar";

import MultiSelect from 'primevue/multiselect';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tooltip from 'primevue/tooltip';
import ProgressBar from 'primevue/progressbar';
import InputText from 'primevue/inputtext';
import ReportService from "../../../../services/ReportsService";
import AppFilterBar from "../../../common/AppFilterBar";

import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import maskMacAddress from "../../../../mixins/StringsMaskMixin";
import isMacAddress from "../../../../mixins/StringsMixins"
import dateFormat from "../../../../mixins/DateMixin";
import AppAnalysisSummaryDialog from "./AppAnalysisSummaryDialog";
import getTimeFromHoursDurationFormatMixin from "@/mixins/DateMixin";
import getTimeFromSecondsMinusThreeHoursMixin from "@/mixins/DateMixin";
import getTimeFromSecondsDurationFormatMixin from "@/mixins/DateMixin";
import {mapGetters} from "vuex";
import idb from '../../../../api/idb';
import {CSV_FIELDS, CSV_LABELS} from "./AppCsvExportOptions";
import exportJsonToCsv from '@/mixins/JsonToCsvMixin';
import {FilterMatchMode} from 'primevue/api';

export default {
    mixins: [maskMacAddress, isMacAddress, dateFormat, getTimeFromHoursDurationFormatMixin,
        getTimeFromSecondsMinusThreeHoursMixin, getTimeFromSecondsDurationFormatMixin, exportJsonToCsv],
    beforeMount() {
        this.initializeGroupItems();
        this.reportsService = new ReportService();
        this.statisticsColumns = [
            {field: 'event_start', header: 'Início', headerStyle: "width: 110px", displayOption: 'timestampSeconds'},
            {field: 'event_end', header: 'Fim', headerStyle: "width: 110px", displayOption: 'timestampSeconds'},
            {field: 'time', header: 'Tempo', headerStyle: "width: 100px", displayOption: 'durationSeconds'},
            {field: 'distance', header: 'Distância (km)', headerStyle: "width: 110px", displayOption: 'number'},
            {field: 'consumption', header: 'Combustível (L)', headerStyle: "width: 130px", displayOption: 'number'},
            {field: 'area', header: 'Área (ha)', headerStyle: "width: 125px", displayOption: 'number'},
            {field: 'yield', header: 'Rendimento (L/ha)', headerStyle: "width: 150px", displayOption: 'number'},
            {field: 'consumption_rate', header: 'Consumo (L/h)', headerStyle: "width: 130px", displayOption: 'number'},
            {field: 'productivity', header: 'Produtividade (ha/h)', headerStyle: "width: 160px", displayOption: 'number'},
            {field: 'refuel', header: 'Abastecimento (L)', headerStyle: "width: 140px", displayOption: 'number'},
        ];
        this.initializeSelectedStatisticsColumns();
        this.loadData();
    },

    data() {
        return {
            reportsService: null,

            isLoading: false,
            eventsReport: [],

            selectedColumns: [],
            columns: [],
            events: [],
            selectedEvents: [],
            groupChecked: [],
            groupIcons: [],
            groupOn: false,
            filteredEventsAfterGrouping: [],

            statisticsColumns: null,
            selectedStatisticsColumns: null,

            filters: {
                'day': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'vehicle_display_id': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'operator_id': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'activity': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'farm': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'field': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'type': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'reason_description': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'shift': {value: null, matchMode: FilterMatchMode.CONTAINS}
            },
            dialogVisible: false,
            groupedRowClicked: {},

            storedAnalysisColumns: null
        }
    },
    props: {
        filter_events_results: {
            type: Array,
            required: true
        }
    },
    methods: {
        initializeSelectedStatisticsColumns() {
            this.storedAnalysisColumns = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_SELECTED_ANALYSIS_COLUMNS));

            if (!this.storedAnalysisColumns) {
                this.selectedStatisticsColumns = this.statisticsColumns;
            } else {
                this.selectedStatisticsColumns = [];
                this.statisticsColumns.forEach(column => {
                    if (this.storedAnalysisColumns[column.field] === true) {
                        this.selectedStatisticsColumns.push(column);
                    }
                });
            }
        },
        openDialog(value) {
            this.dialogVisible = true;
            this.groupedRowClicked = value;
        },
        setDisplayVisibleToFalse() {
            this.dialogVisible = false;
        },
        clearFilters() {
            this.selectedColumns = JSON.parse(JSON.stringify(this.columns));
            this.selectedEvents = JSON.parse(JSON.stringify(this.events));
            this.initializeGroupItems();
            this.filters = {};
            this.groupOn = false;
        },
        setColumnBackgroundColor(index) {
            if (!this.groupChecked[index]) {
                return 'transparent';
            }
            return '#a2c1c4';
        },
        onStatisticsToggle(value) {
            let selectedStatisticsBefore = this.selectedStatisticsColumns;
            this.selectedStatisticsColumns = this.statisticsColumns.filter(col => value.includes(col));
            if (this.selectedStatisticsColumns.length === 0) {
                this.selectedStatisticsColumns = selectedStatisticsBefore;
            }

            this.storedAnalysisColumns = {};
            this.statisticsColumns.forEach(column => {
                this.storedAnalysisColumns[column.field] = this.selectedStatisticsColumns.filter(e => e.field === column.field).length > 0;
            });

            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_SELECTED_ANALYSIS_COLUMNS, JSON.stringify(this.storedAnalysisColumns));
        },
        displayStatistics(value) {
            if (!value ||  typeof value != 'number' || isNaN(value)) {
                return 0
            }
            return value.toFixed(2);
        },
        displayTooltip(value) {
            if (!value) {
                return 'N/D'
            }
            return value
        },
        hideValuesGreaterThanMaximumDisplay(value) {
            const MAX_NUMBER_OF_CHARACTERS = 27;
            if (value && value.length > MAX_NUMBER_OF_CHARACTERS) {
                return value.substring(0, MAX_NUMBER_OF_CHARACTERS);
            }
            return value;
        },
        showStatistic(value) {
            if (!value || value < 0) {
                return 0;
            }
            return value;
        },
        getTypeByMode(mode) {
            if (mode === 1) {
                return 'Atividade';
            }

            if (mode === 2) {
                return 'Parada';
            }

            if (mode === 3) {
                return 'Atividade';
            }

            if (mode === 4) {
                return 'Atividade';
            }

            if (mode === 5) {
                return 'Desligado';
            }

            return "Sem Dados";
        },
        initializeEvents(responses) {
            responses.forEach(response => {
                response.forEach(responseItem => {
                    responseItem['day'] = this.dateFormat(responseItem.event_start);
                    // responseItem['mode'] = responseItem['mode'] + this.shouldAddStopType(responseItem);
                    responseItem['type'] = this.getTypeByMode(responseItem['mode'])

                    responseItem['time'] = this.showStatistic(responseItem.time);
                    responseItem['distance'] = this.showStatistic(responseItem.distance);
                    responseItem['consumption'] = this.showStatistic(responseItem.consumption);
                    responseItem['area'] = this.showStatistic(responseItem.area / 10000);

                    responseItem['refuel'] = this.showStatistic(responseItem.refuel_liters);

                    responseItem['reason_description'] = responseItem['reason_description'] != null ? responseItem['reason_description'] : "";

                    responseItem['yield'] = 0;
                    responseItem['consumption_rate'] = 0;
                    responseItem['productivity'] = 0;



                    // if (responseItem['reason_type'] === 2) {
                    //     maintenanceStopTime = responseItem['event_duration'];
                    // }
                    // responseItem['maintenance_stop_time'] = maintenanceStopTime;
                    //
                    // if (responseItem['time'] > 0) {
                    //     responseItem['mechanical_availability'] = ((responseItem['time'] - maintenanceStopTime) / responseItem['time']) * 100;
                    //     if (responseItem['mechanical_availability'] === 0) {
                    //         responseItem['mechanical_availability'] = 0.001;
                    //     } else if (responseItem['mechanical_availability'] > 100) {
                    //         responseItem['mechanical_availability'] = 100;
                    //     }
                    //
                    // }

                    if (responseItem.time > 0) {
                        responseItem['consumption_rate'] = responseItem.consumption / (responseItem.time / 3600);
                        responseItem['productivity'] = responseItem.area / (responseItem.time / 3600);
                    }

                    if (responseItem.area > 0.01) {
                        responseItem['yield'] = responseItem.consumption / responseItem.area;
                    }

                    // if (responseItem['mode'] === 1) {
                    //     if (responseItem.area > 0.01) {
                    //         responseItem['yield'] = responseItem.consumption / responseItem.area;
                    //     }
                    //
                    //     // if (responseItem['event_duration'] > 0) {
                    //     //     responseItem['efficiency'] = (responseItem.work_time / responseItem.engine_on_time) * 100;
                    //     //     if (responseItem['efficiency'] === 0) {
                    //     //         responseItem['efficiency'] = 0.001;
                    //     //     } else if (responseItem['efficiency'] > 100) {
                    //     //         responseItem['efficiency'] = 100;
                    //     //     }
                    //     //
                    //     //     responseItem['operational_efficiency'] = ((responseItem.work_time + responseItem.maneuver_time) / (responseItem['event_duration'] - maintenanceStopTime)) * 100;
                    //     //     if ((responseItem['event_duration'] - responseItem['maintenance_stop_time']) === 0) {
                    //     //         responseItem['operational_efficiency'] = responseItem['efficiency'];
                    //     //     } else if (responseItem['operational_efficiency'] === 0) {
                    //     //         responseItem['operational_efficiency'] = 0.001;
                    //     //     } else if (responseItem['operational_efficiency'] > 100) {
                    //     //         responseItem['operational_efficiency'] = 100;
                    //     //     }
                    //     // }
                    //
                    // }

                    this.events.push(responseItem);
                });
            })
            this.events.sort(function (a, b) {
                if (a['event_start)'] === b['event_start']) {
                    return 0;
                } else {
                    return (a['event_start'] < b['event_start']) ? -1 : 1;
                }
            });
            this.selectedEvents = JSON.parse(JSON.stringify(this.events));
            this.initializeColumns();
        },
        initializeGroupItems() {
            this.groupChecked = [false, false, false, false, false, false, false, false, false];
            this.groupIcons = [
                {
                    icon: "pi pi-window-minimize",
                    class: "p-button-rounded p-button-secondary p-button-text info-button p-0",
                    disabled: false
                },
                {
                    icon: "pi pi-window-minimize",
                    class: "p-button-rounded p-button-secondary p-button-text info-button p-0",
                    disabled: false
                },
                {
                    icon: "pi pi-window-minimize",
                    class: "p-button-rounded p-button-secondary p-button-text info-button p-0",
                    disabled: false
                },
                {
                    icon: "pi pi-window-minimize",
                    class: "p-button-rounded p-button-secondary p-button-text info-button p-0",
                    disabled: false
                },
                {
                    icon: "pi pi-window-minimize",
                    class: "p-button-rounded p-button-secondary p-button-text info-button p-0",
                    disabled: false
                },
                {
                    icon: "pi pi-window-minimize",
                    class: "p-button-rounded p-button-secondary p-button-text info-button p-0",
                    disabled: false
                },
                {
                    icon: "pi pi-window-minimize",
                    class: "p-button-rounded p-button-secondary p-button-text info-button p-0",
                    disabled: false
                },
                {
                    icon: "pi pi-window-minimize",
                    class: "p-button-rounded p-button-secondary p-button-text info-button p-0",
                    disabled: false
                },
                {
                    icon: "pi pi-window-minimize",
                    class: "p-button-rounded p-button-secondary p-button-text info-button p-0",
                    disabled: false
                }];
        },
        clearTableData() {
            if (this.columns.length > 0) {
                this.events = [];
                this.selectedEvents = [];
                this.selectedColumns = [];
                this.columns = [];
                this.initializeGroupItems();

                this.groupOn = false;
                this.filteredEventsAfterGrouping = [];

            }
        },
        getFieldByIndex(index) {
            if (index === 0) {
                return 'day';
            } else if (index === 1) {
                return 'vehicle_display_id';
            } else if (index === 2) {
                return 'operator_id';
            } else if (index === 3) {
                return 'activity';
            } else if (index === 4) {
                return 'farm';
            } else if (index === 5) {
                return 'field';
            } else if (index === 6) {
                return 'type';
            } else if (index === 7) {
                return 'reason_description';
            } else {
                return 'shift';
            }
        },
        groupButtonClicked(index) {
            this.groupChecked[index] = !this.groupChecked[index];
            if (this.groupChecked[index]) {
                this.groupIcons[index].icon = "pi pi-window-maximize";
                this.groupIcons[index].class = "p-button-rounded p-button-primary p-button-text info-button p-0";
                this.groupOn = true;
                this.disableButtonsSaveForSelectedOne(index);
                this.groupByIndex(index);
            } else {
                this.groupIcons[index].icon = "pi pi-window-minimize";
                this.groupIcons[index].class = "p-button-rounded p-button-secondary p-button-text info-button p-0";
                this.groupOn = false;
                this.enableButtons();
                this.filterSeparatedEvents();
            }
        },
        disableButtonsSaveForSelectedOne(index) {
            this.groupIcons.forEach((icon, iconIndex) => {
                if (iconIndex !== index) {
                    icon.disabled = true;
                }
            });
        },
        enableButtons() {
            this.groupIcons.forEach((icon) => {
                icon.disabled = false;
            });
        },
        addStatisticsToEvent(eventToBeAdded, newEventToAdd) {
            eventToBeAdded['time'] += newEventToAdd['time'] > 0 ? newEventToAdd['time'] : 0;
            eventToBeAdded['distance'] += newEventToAdd['distance'] > 0 ? newEventToAdd['distance'] : 0;
            eventToBeAdded['consumption'] += newEventToAdd['consumption'] > 0 ? newEventToAdd['consumption'] : 0;
            eventToBeAdded['area'] += newEventToAdd['area'] > 0 ? newEventToAdd['area'] : 0;
            eventToBeAdded['maintenance_stop_time'] += newEventToAdd['maintenance_stop_time'] > 0 ? newEventToAdd['maintenance_stop_time'] : 0;
            eventToBeAdded['refuel'] += newEventToAdd['refuel'] > 0 ? newEventToAdd['refuel'] : 0;

            return eventToBeAdded;
        },
        checkStatistics(event) {
            event['time'] = event['time'] > 0 ? event['time'] : 0;
            event['distance'] = event['distance'] > 0 ? event['distance'] : 0;
            event['consumption'] = event['consumption'] > 0 ? event['consumption'] : 0;
            event['area'] = event['area'] > 0 ? event['area'] : 0;
            event['refuel'] = event['refuel'] > 0 ? event['refuel'] : 0;

            event['event_start'] = 0;
            event['event_end'] = 0;

            // if (event['mode'] == 2) {
            //     event['yield'] = 0;
            //     event['productivity'] = 0;
            // }

            return event;
        },
        removeSelectionFields(event, index) {
            this.groupIcons.forEach((icon, fieldIndex) => {
                if (fieldIndex !== index) {
                    if (this.selectedColumns[fieldIndex].options.length > 1) {
                        event[this.getFieldByIndex(fieldIndex)] = '-';
                    }
                }
            });
            return this.checkStatistics(event);
        },
        groupByIndex(index) {
            let groupedEvents = [];
            let field = this.getFieldByIndex(index);
            this.selectedEvents.forEach(event => {
                for (let i = 0; i < groupedEvents.length; i++) {
                    if (groupedEvents[i][field] === event[field]) {
                        groupedEvents[i] = this.addStatisticsToEvent(groupedEvents[i], event);
                        return;
                    }
                }
                groupedEvents.push(this.removeSelectionFields(event, index));
            });


            //Calcular a eficiência apenas no final
            this.selectedEvents.forEach(event => {
                event['yield'] = 0;
                event['consumption_rate'] = 0;
                event['productivity'] = 0;

                // if (event.mode != 2 && (index !== 7 || event.mode === 1)) {
                    if (event.area > 0) {
                        event['yield'] = event.consumption / event.area;
                    }
                    if (event.time > 0) {
                        event['productivity'] = event.area / (event.time / 3600);
                        event['consumption_rate'] = event.consumption / (event.time / 3600);
                    }

                    // if (event['event_duration'] > 0) {
                    //     event['efficiency'] = (event.work_time  / event.engine_on_time) * 100;
                    //     if (event['efficiency'] === 0) {
                    //         event['efficiency'] = 0.001;
                    //     } else if (event['efficiency'] > 100) {
                    //         event['efficiency'] = 100;
                    //     }
                    //
                    //     event['operational_efficiency'] = ((event.work_time + event.maneuver_time) / (event['event_duration'] - event['maintenance_stop_time'])) * 100;
                    //     if ((event['event_duration'] - event['maintenance_stop_time']) === 0) {
                    //         event['operational_efficiency'] = event['efficiency'];
                    //     } else if (event['operational_efficiency'] === 0) {
                    //         event['operational_efficiency'] = 0.001;
                    //     } else if (event['operational_efficiency'] > 100) {
                    //         event['operational_efficiency'] = 100;
                    //     }
                    // }
                // }

                // if (event['event_duration'] > 0) {
                //     event['mechanical_availability'] = ((event['event_duration'] - event['maintenance_stop_time']) / event['event_duration']) * 100;
                //     if (event['mechanical_availability'] === 0) {
                //         event['mechanical_availability'] = 0.001;
                //     } else if (event['mechanical_availability'] > 100) {
                //         event['mechanical_availability'] = 100;
                //     }
                // }


            })

            this.selectedEvents = groupedEvents;
            this.filteredEventsAfterGrouping = JSON.parse(JSON.stringify(this.selectedEvents));
        },
        shouldAddStopType(event) {
            if (event.type.includes('Parada') && event.mode === 8) {
                return " de Manutenção";
            }

            if (event.type.includes('Parada')) {
                return " Operacional";
            }

            return "";
        },
        initializeColumns() {
            this.columns = [
                {field: 'day', header: 'Dia', options: []},
                {field: 'vehicle_display_id', header: 'Veículo', options: []},
                {field: 'operator_id', header: 'Motorista', options: []},
                {field: 'activity', header: 'Atividade', options: []},
                {field: 'farm', header: 'Região', options: []},
                {field: 'field', header: 'Local', options: []},
                {field: 'type', header: 'Condição', options: []},
                {field: 'reason_description', header: 'Motivo', options: []},
                {field: 'shift', header: 'Turno', options: []},
            ];

            let dayOptions = [];
            let chassisOptions = [];
            let operatorOptions = [];
            let activityOptions = [];
            let farmOptions = [];
            let fieldOptions = [];
            let typeOptions = [];
            let reasonOptions = [];
            let shiftOptions = [];
            this.events.forEach(event => {
                dayOptions.push({option: event.day});
                chassisOptions.push({option: event.vehicle_display_id});
                operatorOptions.push({option: event.operator_id});
                activityOptions.push({option: event.activity});
                farmOptions.push({option: event.farm});
                fieldOptions.push({option: event.field});
                typeOptions.push({option: event.type});
                reasonOptions.push({option: event.reason_description});
                shiftOptions.push({option: event.shift});
            })
            const key = 'option';
            this.columns[0].options = [...new Map(dayOptions.map(item => [item[key], item])).values()];
            this.columns[1].options = [...new Map(chassisOptions.map(item => [item[key], item])).values()];
            this.columns[2].options = [...new Map(operatorOptions.map(item => [item[key], item])).values()];
            this.columns[3].options = [...new Map(activityOptions.map(item => [item[key], item])).values()];
            this.columns[4].options = [...new Map(farmOptions.map(item => [item[key], item])).values()];
            this.columns[5].options = [...new Map(fieldOptions.map(item => [item[key], item])).values()];
            this.columns[6].options = [...new Map(typeOptions.map(item => [item[key], item])).values()];
            this.columns[7].options = [...new Map(reasonOptions.map(item => [item[key], item])).values()];
            this.columns[8].options = [...new Map(shiftOptions.map(item => [item[key], item])).values()];

            this.selectedColumns = JSON.parse(JSON.stringify(this.columns));
        },
        filterSeparatedEvents() {
            let newSelectedEvents = [];
            this.events.forEach(event => {
                let addEventToList = false;
                for (let i = 0; i < this.selectedColumns.length; i++) {
                    addEventToList = false;
                    for (let j = 0; j < this.selectedColumns[i].options.length; j++) {
                        if (event[this.selectedColumns[i].field] === this.selectedColumns[i].options[j].option) {
                            addEventToList = true;
                            break;
                        }
                    }
                    if (!addEventToList) {
                        break;
                    }
                }
                if (addEventToList) {
                    newSelectedEvents.push(event);
                }
            });
            this.selectedEvents = JSON.parse(JSON.stringify(newSelectedEvents));
        },
        multiSelectInputMethod(index, value) {
            this.selectedColumns[index].options = value;
            this.filterSeparatedEvents();

            if (this.groupOn) {
                this.groupByIndex(index);
            }
        },
        exportCsvReport() {

            let csvData = [];

            let tempData = JSON.parse(JSON.stringify(this.selectedEvents));

            tempData.forEach((data, index) => {

                let selectedData = {};

                for (let prop in data) {
                    if (Object.prototype.hasOwnProperty.call(data, prop) && data[prop]) {
                        if (prop === 'day' || prop === 'vehicle_display_id' || prop === 'operator_id' ||
                                prop === 'activity' || prop === 'farm' || prop === 'field' || prop === 'type' ||
                                prop === 'reason_description' || prop === 'shift') {
                            selectedData[prop] = data[prop];
                            continue;
                        }

                        if (prop === 'event_start' || prop === 'event_end') {
                            selectedData[prop] = this.getTimeFromSecondsMinusThreeHoursMixin(data[prop]);
                            continue;
                        }

                        if (prop === 'time') {
                            selectedData[prop] = data[prop].toString().replace(".", ",");
                            continue;
                        }

                        if (prop === 'distance' || prop === 'consumption' ||
                                prop === 'area' || prop === 'yield' || prop === 'consumption' ||
                                prop === 'productivity' || prop === 'consumption_rate') {
                            selectedData[prop] = data[prop].toString().replace(".", ",");
                            continue;
                        }

                    }
                }

                csvData.push(selectedData)
            });

            this.exportJsonToCsv(CSV_FIELDS, CSV_LABELS, csvData, 'Análise-Iotag');
        },
        requestDataFromEvents(results) {
            this.clearTableData();
            let vm = this;
            if (results.length > 0) {
                vm.initializeEvents(results);
            }
        },
        async loadData() {
            let tempData = await idb.getData();
            if (tempData) {
                let temp = [];
                let tempWrapper = [];
                //como não diferencia as maquinas, joga tudo em um array para facilitar
                tempData.forEach((cardData) => {
                    if (cardData.events) {
                        cardData.events.forEach((events) => {
                            temp.push(events)
                        })
                    }
                });
                tempWrapper.push(temp);
                this.initializeEvents(tempWrapper);
            }
        },
    },
    computed: {
        ...mapGetters([
            'user'
        ]),
        setDialogWidth() {
            const MIN_WIDTH_TO_DISPLAY_WHOLE_SCREEN = 1024;

            var width = window.innerWidth
                    || document.documentElement.clientWidth
                    || document.body.clientWidth;

            if (width > MIN_WIDTH_TO_DISPLAY_WHOLE_SCREEN) {
                return 'calc(100vw - 140px)';
            }
            return '100vw';
        },
        showMoreButtonIcon() {
            if (this.groupOn) {
                return "pi pi-plus"
            }
            return "pi pi-angle-double-right"
        },

    },
    watch: {
        filter_events_results: function(val) {
            this.requestDataFromEvents(val);
        }
    },
    components: {
        AppDateTimeControlBar,
        MultiSelect,
        InputNumber,
        DataTable,
        Column,
        Button,
        Tooltip,
        AppFilterBar,
        ProgressBar,
        InputText,
        AppAnalysisSummaryDialog
    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>


<style lang="scss">

@import "src/assets/styles/primevue";

.auto-center {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

#table-multiselect .p-multiselect-label-container {
    display: none !important;
}

#table-multiselect .p-multiselect-panel {
    width: 200px !important;
}

#columns-multiselect .p-multiselect-label-container {
    display: none !important;
}

#columns-multiselect .p-multiselect-panel {
    width: 200px !important;
}

.reports {
    margin-top: 10px;
}

.datatable-title {
    color: #495057;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center
}
</style>
