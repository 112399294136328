<template>
    <div>
        <div v-if="eventsReport.length > 0 && report_loading === false" style="height: 100%">
            <div class="timeline-wrapper events">
                <div class="no-padding" v-for="(report, index) in eventsReport" v-bind:key="index" @mouseenter="mouseOverEvent(report)"
                     @mouseleave="mouseOverEvent(null)">
                    <div v-if="report.mode !== 2 && report.mode !== 5 && report.mode !== 6 && report.mode !== 7">
                        <a @click="openShowMore('right', report)">
                            <Card v-bind:class="{'card-background-grey': index % 2 === 0}"
                                  v-tooltip="displayWorkTooltip(report)">
                                <template #title>
                                    <div style="font-size: 18px">
                                        {{getModeName(report.mode)}}
                                        <div style="float: right;">
                                            {{shouldDisplayTimespan(
                                            getTimeFromSecondsDurationFormatMixin(report.time)
                                            )}}
                                            <div style="display: inline">
                                                <img src="../../../assets/icons/icone-play.svg" width="19" height="28"
                                                     alt=""
                                                     class="img-fluid icon-wrapper">
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #subtitle>
                                    <div class="kt-icon-lg" style="color: #000000">
                                        Veículo: {{ report.vehicle_display_id }}
                                    </div>
                                    <div class="kt-icon-lg" style="color: #000000">
                                        Motorista: {{ report.operator_id ?
                                        report.operator_id :'Sem informação' }}
                                    </div>
                                </template>
                                <template #content>
                                    {{ dateFormat(report.event_start) }}
                                    <div v-if="report.mode === 5 || report.mode === 6"
                                         style="float: right;">
                                        {{ getTimeFromSecondsMinusThreeHoursMixin(report.event_start) }}
                                    </div>
                                    <div v-else style="float: right;">
                                        {{ getTimeFromSecondsMinusThreeHoursMixin(report.event_start) }} -
                                        {{getTimeFromSecondsMinusThreeHoursMixin(report.event_end)}}
                                    </div>
                                </template>
                            </Card>
                        </a>
                    </div>
                    <Card v-else v-bind:class="{'card-background-grey': index % 2 === 0}"
                          v-tooltip="displayStopTooltip(report)">
                        <template #header v-if="index === 0">
                            <div class="timeline-header">
                                Linha do Tempo de Apontamentos
                            </div>
                        </template>
                        <template #title>
                            <div style="font-size: 18px">
                                {{ displayEventDescription(report.reason_code, report.mode, report.reason_code)
                                }}
                                <div style="float: right;">
                                    {{ shouldDisplayTimespan(getTimeFromSecondsDurationFormatMixin(report.time))
                                    }}
<!--                                    <div v-if="report.mode === 5" style="display: inline">-->
<!--                                        <img src="../../../assets/icons/icone-entrar.svg" width="20" height="30" alt=""-->
<!--                                             class="img-fluid icon-wrapper">-->
<!--                                    </div>-->
<!--                                    <div v-if="report.mode === 5" style="display: inline">-->
<!--                                        <img src="../../../assets/icons/icone-sair.svg" width="20" height="30" alt=""-->
<!--                                             class="img-fluid icons icon-wrapper">-->
<!--                                    </div>-->
                                    <div style="display: inline">
                                        <img src="../../../assets/icons/icone-parada-red.svg" width="20" height="30"
                                             alt=""
                                             class="img-fluid icon-wrapper">
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #subtitle>
                            <div class="kt-icon-lg" style="color: #000000">
                                Veículo: {{ report.vehicle_display_id }}
                            </div>
                            <div class="kt-icon-lg" style="color: #000000">
                                Motorista: {{ report.operator_id ? report.operator_id :'Sem informação'
                                }}
                            </div>
                        </template>
                        <template #content>
                            {{ dateFormat(report.event_start) }}
                            <div v-if="report.mode === 5 || report.mode === 6" style="float: right;">
                                {{ getTimeFromSecondsMinusThreeHoursMixin(report.event_start) }}
                            </div>
                            <div v-else style="float: right;">
                                {{ getTimeFromSecondsMinusThreeHoursMixin(report.event_start) }} -
                                {{getTimeFromSecondsMinusThreeHoursMixin(report.event_end)}}
                            </div>
                        </template>
                    </Card>
                </div>
            </div>

            <Dialog :style="{width: setDialogWidth}" :visible.sync="displayShowMore"
                    :position="position" :modal="true">
                <template #header>
                    <div>
                        <div style="font-size: 24px;font-weight: bold">
                            Atividade
                        </div>
                        <div>
                            de {{getTimeFromSecondsMinusThreeHoursMixin(eventStatistics.startTime)}} a
                            {{getTimeFromSecondsMinusThreeHoursMixin(eventStatistics.endTime)}}
                        </div>
                    </div>
                </template>
                <strong>Área: </strong> {{eventStatistics.total_area}} ha <br>
                <strong>Consumo: </strong> {{eventStatistics.average_fuel_consumption}} L/h <br>
                <strong>Velocidade: </strong> {{eventStatistics.speed}} km/h <br>
                <strong>Produtividade: </strong> {{eventStatistics.productivity}} ha/h <br>
                <div :style="{width: setChartWidth, height: '45vh'}">

                    <app-vehicles-track-charts-pie
                        :chart-data="chart_data">
                    </app-vehicles-track-charts-pie>

                </div>
                <template #footer>
                    <app-button
                        type="primary"
                        class="auto-center"
                        @handleClick="closeShowMore">Ok
                    </app-button>
                </template>
            </Dialog>
        </div>
        <div v-else-if="report_loading === true">
            <div style="text-align: center;width: 100%">
                <div class="kt-spinner kt-spinner--lg kt-spinner--dark"
                     style="display:inline-block;margin:0 auto;padding-top: 2rem"></div>
            </div>
        </div>
        <div v-else class="show-no-data">
            Sem dados no período
        </div>
    </div>
</template>

<script>

    import Accordion from 'primevue/accordion';
    import AccordionTab from 'primevue/accordiontab';
    import Card from 'primevue/card';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import Button from 'primevue/button'
    import Dialog from 'primevue/dialog';
    import Tooltip from 'primevue/tooltip';

    import dateFormat from "../../../mixins/DateMixin";
    import getTimeFromSecondsMinusThreeHoursMixin from "@/mixins/DateMixin";
    import getTimeFromSecondsDurationFormatMixin from "@/mixins/DateMixin";
    import getTimeFromHoursDurationFormatMixin from "@/mixins/DateMixin";
    import AppVehiclesDataTable from "./AppVehiclesDataTable";
    import AppButton from "../../common/AppButton";
    import AppVehiclesTrackChartsPie from "../../common/charts/AppPieChartsNoTheme";

    export default {
        mixins: [getTimeFromSecondsMinusThreeHoursMixin, getTimeFromSecondsDurationFormatMixin, dateFormat,
            getTimeFromHoursDurationFormatMixin],
        data() {
            return {
                displayShowMore: false,
                position: 'center',
                eventStatistics: {},
                chart_data: null,
                chartWidth: '22vw',
            }
        },
        props: {
            eventsReport: {
                type: Array,
                required: true
            },
            report_loading: {
                type: Boolean,
                required: true
            },
        },
        methods: {
            getModeName(mode) {
                if (mode === 1) {
                    return 'Produzindo';
                }

                if (mode === 2) {
                    return 'Ocioso';
                }

                if (mode === 3) {
                    return 'Manobrando';
                }

                if (mode === 4) {
                    return 'Deslocando';
                }

                if (mode === 5) {
                    return 'Desligado';
                }

                if (mode === 6) {
                    return 'Aguardando dados';
                }

                if (mode === 7) {
                    return 'Sem informação';
                }

                return 'N/D';
            },
            mouseOverEvent(event) {
                // this.$emit('mouseOverEvent', event)
            },
            shouldDisplayTimespan(val) {
                if (val === '0s') {
                    return "";
                }
                return val;
            },
            displayEventDescription(reasonDescription, mode, reason) {
                if (mode === 5) {
                    return 'Desligado';
                }

                if (mode === 6) {
                    return 'Aguardando dados';
                }

                if (mode === 7) {
                    return 'Sem informação';
                }

                if (reason === 'Não Identificado') {
                    return 'Ocioso (Sem Apontamento)';
                }

                const MAXIMUM_NUMBER_OF_CHARACTERS = 23;
                if (reasonDescription && reasonDescription.length > MAXIMUM_NUMBER_OF_CHARACTERS) {
                    return reasonDescription.substring(0, MAXIMUM_NUMBER_OF_CHARACTERS) + "...";
                }
                return reasonDescription;
            },
            openShowMore: function (position, report) {
                this.position = position;
                this.displayShowMore = true;

                this.eventStatistics.total_area = this.formatStatistics(report.area / 10000, 3);
                this.eventStatistics.average_fuel_consumption = this.formatStatistics((report.consumption / report.time), 3);
                this.eventStatistics.speed = this.formatStatistics((report.distance / (report.time / 3600)), 3);
                this.eventStatistics.productivity = this.formatStatistics(((report.area / 10000) / (report.time / 3600)), 3);
                this.eventStatistics.startTime = report.event_start;
                this.eventStatistics.endTime = report.event_end;


                setTimeout(() => {
                    this.chart_data =
                        [
                            {name: 'Produzindo', value: report.mode === 1 ? 100 : 0, itemStyle: {color: '#2ca02c'}},
                            {name: 'Ocioso', value: report.mode === 2 ? 100 : 0, itemStyle: {color: '#d62728'}},
                            {name: 'Manobrando', value: report.mode === 3 ? 100 : 0, itemStyle: {color: '#ff7e0e'}},
                            {name: 'Deslocando', value: report.mode === 4 ? 100 : 0, itemStyle: {color: '#1f76b4'}}
                        ];
                }, 500);
            },
            closeShowMore: function () {
                this.displayShowMore = false;
                this.chart_data = [];
            },
            formatStatistics: function (statistics, fractionDigits) {
                return statistics.toFixed(fractionDigits);
            },
            displayWorkTooltip: function (report) {
                let total_area = this.formatStatistics(report.area / 10000, 3);
                let average_fuel_consumption = this.formatStatistics((report.consumption / report.time), 3);
                let speed = this.formatStatistics((report.distance / (report.time / 3600)), 2);
                let productivity = this.formatStatistics(((report.area / 10000) / (report.time / 3600)), 3);

                let returnString = 'Área: ' + total_area + ' ha\n';
                returnString += 'Consumo: ' + average_fuel_consumption + ' L/h\n';
                returnString += 'Velocidade: ' + speed + ' km/h\n';
                returnString += 'Produção: ' + productivity + ' ha/h';

                return returnString;
            },
            displayStopTooltip: function (report) {
                if (report.mode === 5 || report.mode === 6) {
                    return "";
                }
                return report.reason_code;
            }
        },
        computed: {
            setDialogWidth() {
                const MIN_WIDTH_TO_DISPLAY_WHOLE_SCREEN = 600;

                var width = window.innerWidth
                    || document.documentElement.clientWidth
                    || document.body.clientWidth;

                if (width > MIN_WIDTH_TO_DISPLAY_WHOLE_SCREEN) {
                    return '22vw';
                }
                return '95vw';
            },
            setChartWidth() {
                const MIN_WIDTH_TO_DISPLAY_WHOLE_SCREEN = 600;

                var width = window.innerWidth
                    || document.documentElement.clientWidth
                    || document.body.clientWidth;

                if (width > MIN_WIDTH_TO_DISPLAY_WHOLE_SCREEN) {
                    return '20vw';
                }
                return '85vw';
            }
        },
        directives: {
            'tooltip': Tooltip
        },
        components: {
            Accordion, AccordionTab, Card, DataTable, Column, 'data-table-events': AppVehiclesDataTable,
            Button, Dialog, AppButton, Tooltip, AppVehiclesTrackChartsPie
        },
    }
</script>

<style scoped lang="scss">

    a:hover {
        cursor: pointer
    }

    .icons {
        margin-top: 6px;
        float: right
    }

    .width-auto {
        width: auto !important;
    }

    .icon-size {
        width: 20px;
        height: 30px;
    }

    .icon-wrapper {
        margin-top: 3px;
        margin-left: 5px;
        float: right;
    }

    .card-background-grey {
        background-color: #e6e6e6;
    }

    .timeline-wrapper {
        overflow: auto;
        text-align: left;
    }

    .timeline-wrapper.events {
        height: 100%;
    }

    .timeline-wrapper.alerts {
        height: 100%;
    }

    .timeline-header {
        text-align: center;
        font-size: 23px;
        font-weight: 900;
    }

    .show-no-data {
        background-color: #323234;
        height: 100%;
        color: white;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

</style>
