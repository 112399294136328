<template>
    <div class="pb-5">
        <div class="pt-4 field-title d-flex justify-content-between align-items-center mb-sm-4 mb-1">
            <div class="pl-5">
                <div v-if="isEditing">
                    <i class="pi pi-times" style="font-size: 20px" @click="cancelEdit"/>
                </div>
                <div v-else>
                    <i class="pi invisible pi-times" style="font-size: 20px; color: white"/>
                </div>
            </div>
            <div>
                Informações gerais
            </div>
            <div class="pr-5">
                <div v-if="!isEditing">
                    <i class="pi pi-pencil" style="font-size: 20px" @click="toggleEdit"/>
                </div>
                <div v-else>
                    <i class="pi pi-check" style="font-size: 20px" @click="saveEdit"/>
                </div>
            </div>
        </div>
        <div class="row pb-1 pt-sm-1 pt-2">
            <div class="col-sm-6 col-12">
                <div class="d-flex flex-column align-items-center mt-1">
                    <div class="field-title">
                        Chassis
                    </div>
                    <div class="field-value">
                        <div v-if="vehicle.identification_number">
                            {{ vehicle.identification_number }}
                        </div>
                        <div v-else>
                            N/D
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column align-items-center mt-3">
                    <div class="field-title">
                        Fabricante
                    </div>
                    <div class="field-value">
                        <Dropdown v-if="isEditing" v-model="selectedManufacturer" :options="manufacturers"
                                  optionLabel="name" :dataKey="'id'" :filter="true" filterPlaceholder="Procurar"
                                  @change="updateModels" placeholder="Selecione o fabricante">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <span>{{ slotProps.value.name }}</span>
                                </div>
                            </template>
                            <template #option="slotProps">
                                <span>{{ slotProps.option.name }}</span>
                            </template>
                        </Dropdown>
                        <div v-else>
                            <div v-if="vehicleManufacturer">
                                {{ vehicleManufacturer.name }}
                            </div>
                            <div v-else>
                                N/D
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column align-items-center mt-3">
                    <div class="field-title">
                        Frota
                    </div>
                    <div class="field-value d-flex flex-column align-items-center">
                        <InputText v-if="isEditing" style="width: 80%" v-model="fleet"/>
                        <div v-else>
                            <div v-if="vehicle.identification">
                                {{ vehicle.identification }}
                            </div>
                            <div v-else>
                                N/D
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column align-items-center mt-3">
                    <div class="field-title">
                        Ano de fabricação
                    </div>
                    <div class="field-value d-flex flex-column align-items-center">
                        <InputNumber v-if="isEditing" style="width: 50%" :useGrouping="false"
                                     v-model="manufacturingYear"/>
                        <div v-else>
                            <div v-if="vehicle.year">
                                {{ vehicle.year }}
                            </div>
                            <div v-else>
                                N/D
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-12">
                <div class="d-flex flex-column align-items-center mt-1">
                    <div class="field-title">
                        Dispositivo
                    </div>
                    <div class="field-value">
                        <div v-if="vehicle.mac">
                            {{ vehicle.mac }}
                        </div>
                        <div v-else>
                            N/D
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column align-items-center mt-3">
                    <div class="field-title">
                        Modelo
                    </div>
                    <div class="field-value">
                        <Dropdown v-if="isEditing" v-model="selectedModel" :options="models" optionLabel="name"
                                  :dataKey="'id'" :filter="true" filterPlaceholder="Procurar"
                                  placeholder="Selecione o modelo">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <span>{{ slotProps.value.name }}</span>
                                </div>
                            </template>
                            <template #option="slotProps">
                                <span>{{ slotProps.option.name }}</span>
                            </template>
                        </Dropdown>
                        <div v-else>
                            <div v-if="vehicleModel">
                                {{ vehicleModel.name }}
                            </div>
                            <div v-else>
                                N/D
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column align-items-center mt-3">
                    <div class="field-title">
                        Tipo
                    </div>
                    <div class="field-value">
                        <div v-if="isEditing">
                            <div v-if="selectedModel">
                                {{ selectedModel.display_vehicle_type }}
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="vehicleModel">
                                {{ vehicleModel.display_vehicle_type }}
                            </div>
                            <div v-else>
                                N/D
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";

export default {
    data() {
        return {
            isEditing: false,
            saving: false,
            selectedManufacturer: null,
            selectedModel: null,
            fleet: null,
            manufacturingYear: null,
        }
    },
    props: {
        vehicle: {
            type: Object,
            required: true
        },
        vehicleManufacturer: {
            type: Object
        },
        vehicleModel: {
            type: Object
        },
        manufacturers: {
            type: Array,
            required: true
        },
        models: {
            type: Array,
            required: true
        }
    },
    methods: {
        toggleEdit() {
            this.isEditing = true
            this.selectedManufacturer = this.vehicleManufacturer
            this.selectedModel = this.vehicleModel
            this.fleet = this.vehicle.identification
            this.manufacturingYear = this.vehicle.year
        },
        cancelEdit() {
            this.isEditing = false
        },
        saveEdit() {
            if (this.invalidInput()) {
                return
            }

            this.saving = true
            let newVehicle = {
                identification: this.fleet,
                model_id: this.selectedModel.id,
                year: this.manufacturingYear
            }
            this.$emit('saveVehicle', newVehicle)
        },
        invalidInput() {
            if (!this.fleet) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'É necessário definir a frota',
                    life: 5000
                });
                return true
            }

            if (!this.selectedManufacturer) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'É necessário selecionar um fabricante',
                    life: 5000
                });
                return true
            }

            if (!this.selectedModel) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'É necessário selecionar um modelo',
                    life: 5000
                });
                return true
            }

            if (this.manufacturingYear) {
                let maxYear = 2030
                let minYear = 1950
                if (this.manufacturingYear < minYear || this.manufacturingYear > maxYear) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Ano de fabricação inválido, insira um valor entre ' + minYear + ' e ' + maxYear,
                        life: 5000
                    });
                    return true
                }
            }

            return false
        },
        updateModels() {
            this.selectedModel = null
            this.$emit('getModels', this.selectedManufacturer.id, true)
        }
    },
    watch: {
        vehicleModel: {
            deep: true,
            handler() {
                if (this.saving) {
                    this.isEditing = false
                    this.saving = false
                }
            }
        }
    },
    components: {
        InputText, Dropdown, InputNumber
    }
}
</script>

<style scoped>

.pi {
    cursor: pointer;
}

.invisible {
    cursor: auto !important;
}

.field-title {
    color: grey;
    font-size: large;
}

.field-value {
    color: black;
    font-weight: bold;
    font-size: large;
}
</style>
