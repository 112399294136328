<template>
    <v-chart
        class="chart"
        ref="chartInstance"
        :option="chartOption"
        :autoresize="true"
        theme="custom-theme"
    />
</template>

<script>
import {use, registerTheme} from "echarts/core";
import {
    TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LegendComponent,
} from 'echarts/components';
import {LineChart} from 'echarts/charts';
import {CanvasRenderer} from 'echarts/renderers';

import VChart from "vue-echarts";

import customTheme from '../../../configurations/iotag_echarts_theme.json'

use([
    CanvasRenderer,
    LineChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    ToolboxComponent
]);

registerTheme("custom-theme", customTheme);

export default {

    components: {
        VChart
    },
    beforeMount() {
        this.setupChart();
    },
    data() {
        return {
            chartOption: null,

            //key = id
            dataMap: new Map(),
            lastTimestampEmitted: null,
            isHourMeter: false
        }
    },
    mounted() {
        this.setupLegendClickEvent();
    },
    methods: {
        setupLegendClickEvent() {
            let chart = this.$refs.chartInstance.chart;
            chart.on('legendselectchanged', (params) => {
                if(!this.isHourMeter) return

                chart.dispatchAction({
                    type: 'legendAllSelect'
                });

                chart.dispatchAction({
                    type: 'legendInverseSelect'
                });

                chart.dispatchAction({
                    type: 'legendSelect',
                    name: params.name
                });
            });
        },
        setupChart() {
            let vm = this;
            this.chartOption = {
                legend: {
                    top: 5,
                    bottom: 5,
                    type: 'scroll',
                    data: [],
                    selected: {}
                },
                title: {
                    textStyle: {
                        color: "grey",
                        fontSize: 20
                    },
                    text: "Sem dados no período",
                    left: "center",
                    top: "center"
                },
                tooltip: {
                    axisPointer: {
                        snap: true
                    },
                    trigger: 'axis',
                    confine: true,
                    formatter: function (args) {
                        if(args[0].value[1] !== null){
                            let date = new Date(args[0].axisValue).toLocaleDateString() + " " + new Date(args[0].axisValue).toLocaleTimeString();

                            let tooltip = `${date}<br> `;

                            args.forEach(({marker, seriesName, value}) => {
                                value = value || [0, 0];
                                tooltip += `${marker} <b>${value[1]} ${vm.seriesUnit} </b> ${seriesName} <br>`;
                            });

                            vm.emitCustomMouseOver(args);

                            return tooltip;
                        }
                    }
                },
                grid: {
                    left: '1%',
                    right: '2%',
                    bottom: '4%',
                    top: '15%',
                    containLabel: true
                },
                xAxis: {
                    type: 'time',
                },
                yAxis: {
                    type: 'value',
                    min: 'dataMin',
                    show: true
                },
                dataZoom: [
                    {
                        type: 'inside',
                        start: 0,
                        end: 100
                    }
                ],
                series: []
            }
        },
        /**
         * o mouseover padrao emite o evento somente quando passa o mouse exatamente sobre o ponto de dado
         * */
        emitCustomMouseOver(args) {

            //validacao para não ficar emitindo eventos repetidos
            if (this.lastTimestampEmitted !== args[0].value[0]) {
                this.lastTimestampEmitted = args[0].value[0];
                this.$emit('customMouseOver', {x: args[0].value[0], y: args[0].value[1]})
            }

        },
        showNoDataMessage() {
            this.$refs.chartInstance.chart.setOption({
                title: {
                    show: true
                }
            })
        },
        hideNoDataMessage() {
            this.$refs.chartInstance.chart.setOption({
                title: {
                    show: false
                }
            })
        },
        newDataArray(data) {
            let tempArray = JSON.parse(JSON.stringify(data));
            tempArray.forEach(temp => {
                if(temp.markArea || temp.data == undefined || temp.data == null || temp.data.length === 0){
                    return;
                }
                let TOTAL_POINTS = temp.data.length;
                let TOTAL_TIME = temp.data[TOTAL_POINTS-1][0] - temp.data[0][0];
                let TIME_PARAMETER = 4;
                let TIME_TO_DISCONNECT_POINT = (TOTAL_TIME / TOTAL_POINTS) * TIME_PARAMETER;
                for (let i = 1; i < temp.data.length; i++) {
                    if (temp.data[i][0] - temp.data[i - 1][0] >= TIME_TO_DISCONNECT_POINT) {
                        let newArr = [temp.data[i - 1][0] + 1000, null];
                        temp.data.splice(i, 0, newArr);
                        i = i + 1;
                    }
                }
            })
            return tempArray;
        },
    },
    props: {
        chartData: {
            type: Array,
            default() {
                return []
            }
        },
        seriesName: {
            type: String,
            default: ''
        },
        seriesUnit: {
            type: String,
            default: ''
        }
    },
    watch: {
        chartData: {
            deep: true,
            handler(val) {
                if (val.length === 0) {
                    this.$refs.chartInstance.chart.clear();
                    this.setupChart();
                    this.showNoDataMessage();
                } else {
                    this.isHourMeter = true;
                    let isPie = false;
                    let graphData = this.newDataArray(val);
                    graphData.forEach(series => {
                        if (!series.markArea) {
                            series.markArea = null;
                            this.isHourMeter = false;
                        }
                        
                        if(series.itemStyle){
                            isPie = true
                        }
                    });

                    if(isPie){
                        this.chartOption.legend.data = [];
                        this.chartOption.legend.selected = {};
                        this.chartOption.series = JSON.parse(JSON.stringify(graphData));
                        this.hideNoDataMessage();
                        return;
                    }

                    let data = graphData.map(element => element.name);
                    this.chartOption.legend.data = data;

                    let selected = data.reduce((acc, name, index) => {
                        acc[name] = this.isHourMeter ? (index === 0) : true;
                        return acc;
                    }, {});
                    this.chartOption.legend.selected = selected;

                    this.chartOption.series = JSON.parse(JSON.stringify(graphData));
                    this.hideNoDataMessage();
                }
            }
        },
    },
};
</script>

<style scoped>
</style>
