// @formatter:off

export const CHARTS_TYPES_DATA = [
    {key: 'events_timeline', value: 'events_timeline', name: 'Linha do Tempo de Apontamentos', unit: ''},
    {key: 'events_summary', value: 'events_summary', name: 'Resumo de Apontamentos', unit: ''},
    {key: 'atividade', value: 'atividade', name: 'Atividade (Análise)', unit: ''},
    {key: 'gasto_combustivel', value: 'gasto_combustivel', name: 'Combustível (Análise)', unit: ''},
    {key: 'hour_meter', value: 'hour_meter', name: 'Horímetro ', unit: ''},
    {key: 'altitude', value: 'altitude', name: 'Altitude', unit: 'm'},
    {key: 'engine_instantaneous_fuel_economy', value: 'engine_instantaneous_fuel_economy_series', name: 'Consumo (l/h)', unit: 'l/h'},
    {key: 'selected_gear', value: 'selected_gear', name: 'Marcha', unit: ''},
    {key: 'number_of_satellites', value: 'number_of_satellites', name: 'Nº de satélites', unit: ''},
    {key: 'vehicle_slip_percentage', value: 'vehicle_slip_percentage', name: 'Patinagem (%)', unit: '%'},
    {key: 'positioning_precision', value: 'positioning_precision', name: 'Precisão (m)', unit: 'm'},
    {key: 'vehicle_rpm', value: 'vehicle_rpm', name: 'Rotação (rpm)', unit: 'rpm'},
    {key: 'gsm_signal', value: 'gsm_signal', name: 'Sinal GSM (dbm)', unit: 'dbm'},
    {key: 'gsm_band', value: 'gsm_band', name: 'Banda GSM', unit: ''},
    {key: 'temperature', value: 'temperature', name: 'Temperatura (°C)', unit: '°C'},
    {key: 'actual_engine_percent_torque', value: 'actual_engine_percent_torque', name: 'Torque (%)', unit: '%'},
    {key: 'gps_vehicle_speed', value: 'gps_vehicle_speed', name: 'Velocidade (km/h)', unit: 'km/h'},
    {key: 'engine_oil_level', value: 'engine_oil_level', name: 'Nível de Óleo (%)', unit: '%'},
    {key: 'fuel_level', value: 'fuel_level', name: 'Nível do Tanque (%)', unit: '%'},
];

export const  VEHICLES_STATUSES_LIST = [
    {name: "Conectado", code: "WORKING"},
    {name: "Sem conexão", code: "DISCONNECTED"},
    {name: "Sem conexão por mais de 24h", code: "DISCONNECTED_24"},
];

export const OPTIONS_SELECT_WORK_TYPE = [
    {key: 'TOTAL', value: 'Total'},
    {key: 'TRABALHANDO', value: 'Trabalhando'},
    {key: 'OCIOSO', value: 'Ocioso'},
    {key: 'MANOBRANDO', value: 'Manobrando'},
    {key: 'DESLOCANDO', value: 'Deslocando'}
];

export const MAPS_OVERLAY_OPEN_WEATHER_TYPES = Object.freeze({
  PRECIPITATION: "precipitation",
  CLOUDS: "clouds_new",
  TEMPERATURE: "temp_new"
});

export const MAPS_OVERLAY_WEATHER_TYPES = Object.freeze({
  PRECIPITATION: "precipitation",
  CLOUDS: "clouds",
  TEMPERATURE: "temperature",
  REMOVE:'remove'
});

export const MAPS_OVERLAY_WORK_TYPES = Object.freeze({
    TRACK: "track",
    APPLICATION: "application",
    SPEED: "speed",
    HUMIDITY: "humidity"
});

export const MAPS_DEFAULT_CONFIGS = Object.freeze({
  ZOOM:4,
});



// @formatter:on
