<template>
    <div class="pb-5">
        <div class="pt-4 field-title d-flex justify-content-between align-items-center mb-2">
            <div class="pl-5">
                <div v-if="isEditing">
                    <i class="pi pi-times" style="font-size: 20px" @click="cancelEdit"/>
                </div>
                <div v-else>
                    <i class="pi pi-times" style="font-size: 20px; color: white"/>
                </div>
            </div>
            <div>
                Atividade atual
            </div>
            <div class="pr-5">
                <div v-if="!isEditing">
                    <i class="pi pi-pencil" style="font-size: 20px" @click="toggleEdit"/>
                </div>
                <div v-else>
                    <i class="pi pi-check" style="font-size: 20px" @click="saveEdit"/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-12">
                <div class="d-flex flex-column align-items-center">
                    <div class="field-title">
                        Atividade
                    </div>
                    <div class="field-value">
                        <Dropdown v-if="isEditing" v-model="selectedActivity" :options="activities" :filter="true"
                                  optionLabel="description" :dataKey="'identification'" filterPlaceholder="Procurar"
                                  placeholder="Selecione a atividade">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <span>{{ slotProps.value.description }}</span>
                                </div>
                            </template>
                            <template #option="slotProps">
                                <span>{{ slotProps.option.description }}</span>
                            </template>
                        </Dropdown>
                        <div v-else>
                            <div v-if="vehicleActivity.activity">
                                {{ vehicleActivity.activity.description }}
                            </div>
                            <div v-else>
                                N/D
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-12">
                <div class="d-flex flex-column align-items-center mt-sm-0 mt-3">
                    <div class="field-title">
                        Largura de trabalho
                    </div>
                    <div class="field-value d-flex flex-column align-items-center">
                        <InputNumber v-if="isEditing" suffix=" m" :useGrouping="true" style="width: 50%"
                                     :minFractionDigits="2" :maxFractionDigits="2" v-model="workingWidth"/>
                        <div v-else>
                            <div v-if="vehicleActivity.workingWidth">
                                {{ vehicleActivity.workingWidth }} m
                            </div>
                            <div v-else>
                                N/D
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";

export default {
    data() {
        return {
            isEditing: false,
            saving: false,
            workingWidth: null,
            selectedActivity: null,
        }
    },
    props: {
        vehicleActivity: {
            type: Object,
            required: true
        },
        activities: {
            type: Array,
            required: true
        }
    },
    methods: {
        toggleEdit() {
            this.isEditing = true
            this.workingWidth = this.vehicleActivity.workingWidth ? this.vehicleActivity.workingWidth : 0
            this.selectedActivity = this.vehicleActivity.activity
        },
        saveEdit() {
            if (this.invalidInput()) {
                return
            }

            this.saving = true
            let postBody = {
                activity_identification: this.selectedActivity.identification,
                working_width: this.workingWidth
            }
            this.$emit('saveActivity', postBody)
        },
        invalidInput() {
            if (!this.selectedActivity) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'É necessário selecionar uma atividade',
                    life: 5000
                });
                return true
            }

            if (this.workingWidth <= 0) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Largura de trabalho inválida',
                    life: 5000
                });
                return true
            }

            return false
        },
        cancelEdit() {
            this.isEditing = false
        }
    },
    watch: {
        vehicleActivity: {
            deep: true,
            handler() {
                if (this.saving) {
                    this.isEditing = false
                    this.saving = false
                }
            }
        }
    },
    components: {
        InputNumber, Dropdown
    }
}
</script>

<style scoped>

.pi {
    cursor: pointer;
}

.field-title {
    color: grey;
    font-size: large;
}

.field-value {
    color: black;
    font-weight: bold;
    font-size: large;
}

</style>
