<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <div class="kt-portlet__head-label">
                    <Dropdown v-model="selectedOrg" :options="organizations"
                              optionLabel="display_name" placeholder="Organização"
                              class="auto-center" :dataKey="'id'"
                              :filter="true"
                              style="width: 250px"
                              @input="saveChosenOptionsToLocalStorage">
                        <template #option="slotProps" style="width: 500px">
                            <div v-if="loadingOrgs"> Carregando, aguarde</div>
                            <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                                -
                            </div>
                            <div style="padding-right: 20px" v-else>
                                {{ slotProps.option.display_name }}
                            </div>
                        </template>
                    </Dropdown>
                </div>
            </div>
            <div class="kt-portlet__body no-padding">
                <div class="card data-table-width">
                    <DataTable :value="gridData" :paginator="true" :rows="50" :rowHover="true" :loading="loading" stripedRows  :showGridlines="true"
                                :sortOrder="-1" sortField="last_update_date"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                :rowsPerPageOptions="[10,25,50]"
                                currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} Dispositivos"
                                :selection.sync="selected_devices" selectionMode="single" dataKey="mac_address"
                                class="p-datatable-customers"
                                :filters.sync="filters" filterDisplay="row" responsiveLayout="scroll">
                        <template #header>
                            <div style="text-align: right">
                                <Button icon="pi pi-external-link" label="Exportar" @click="exportCsv" style="font-size: 13px;" />
                            </div>
                        </template>
                        <template #empty>
                            Sem dados para exibir. Selecione um cliente.
                        </template>
                        <template #loading>
                            Carregando dipositivos, por favor aguarde
                        </template>
                        <Column field="last_contact" header="Horário" headerClass="headerClass" bodyClass="bodyClass" :styles="{'min-width':'11rem'}"
                                    :showFilterMenu="false" :showClearButton="false"
                                    :sortable="true" filterMatchMode="contains">
                            <template #body="slotProps">
                                {{dateHourFormat(slotProps.data.last_contact)}}
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="city" header="Município" headerClass="headerClass" bodyClass="bodyClass" :styles="{'min-width':'12rem'}"
                                :showFilterMenu="false" :showClearButton="false" :sortable="true" filterMatchMode="contains">
                            <template #body="slotProps">
                                {{slotProps.data.city}}
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="state" header="Estado" headerClass="headerClass" :styles="{'min-width':'5rem'}" :showFilterMenu="false" :showClearButton="false"
                                :sortable="true" filterMatchMode="contains" bodyClass="bodyClass">
                            <template #body="slotProps">
                                {{slotProps.data.state}}
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="mac_address" header="Serial" headerClass="headerClass" :styles="{'min-width':'10rem'}" :showFilterMenu="false" :showClearButton="false"
                                :sortable="true" filterMatchMode="custom" bodyClass="bodyClass"
                                :filterFunction="filterValue">
                            <template #body="slotProps">
                                {{slotProps.data.mac_address}}
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="model" header="Versão" headerClass="headerClass" :styles="{'min-width':'10rem'}" :showFilterMenu="false" :showClearButton="false"
                                :sortable="true" filterMatchMode="contains" bodyClass="bodyClass">
                            <template #body="slotProps">
                                {{slotProps.data.model}}
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                                </template>
                        </Column>
                        <Column field="iccid" header="Assinatura" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass"
                                :sortable="true" filterMatchMode="contains" :styles="{'min-width':'12rem'}">
                            <template #body="slotProps">
                                {{slotProps.data.iccid}}
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="fleet" header="Frota" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass"
                                :sortable="true" filterMatchMode="contains" :styles="{'min-width':'12rem'}">
                            <template #body="slotProps">
                                {{slotProps.data.fleet}}
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="vin" header="Chassi" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass"
                                :sortable="true" filterMatchMode="contains" :styles="{'min-width':'12rem'}">
                            <template #body="slotProps">
                                {{slotProps.data.vin}}
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="vehicle_manufacturer" header="Marca" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass"
                                :sortable="true" filterMatchMode="contains" :styles="{'min-width':'12rem'}">
                            <template #body="slotProps">
                                {{slotProps.data.vehicle_manufacturer}}
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="vehicle_model" header="Modelo" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass"
                                :sortable="true" filterMatchMode="contains" :styles="{'min-width':'12rem'}">
                            <template #body="slotProps">
                                {{slotProps.data.vehicle_model}}
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {router} from '../../../router';
import axios from '../../../axios/axios-auth';
import {mapGetters} from 'vuex';
import maskCpfCnpj from "../../../mixins/StringsMaskMixin";
import dateHourFormat from "../../../mixins/DateMixin";
import maskMacAddress from "../../../mixins/StringsMaskMixin";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import ProgressBar from 'primevue/progressbar';
import Button from 'primevue/button'
import DevicesService from "../../../services/DevicesService";
import {STATUS} from "../../../constants/status";
import AppButton from "../../common/AppButton";
import filterValue from "../../../mixins/FilterFunctionsDataTables";
import formatData from "../../../mixins/SizesMixin"
import OrganizationsService from "@/services/OrganizationsService";
import orderCustomFieldByNaturalOrder from "@/mixins/ArrayMixin";
import exportJsonToCsv from '@/mixins/JsonToCsvMixin';
import {FilterMatchMode} from 'primevue/api';
import {FilterService} from 'primevue/api';
const DATE_FILTER = 'DATE FILTER';

export default {
    mixins: [maskCpfCnpj, maskMacAddress, status, dateHourFormat, filterValue, formatData, orderCustomFieldByNaturalOrder, exportJsonToCsv],
    mounted() {
        this.devicesService = new DevicesService();
        FilterService.register(DATE_FILTER, (value, filter) => {
            if (filter === undefined || filter === null || filter.trim() === '') {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            return this.dateHourFormat(value).includes(filter);
        });
        this.organizationsService = new OrganizationsService();
        this.getOrganizations();
    },
    data() {
        return {
            status_list: STATUS,
            devicesService: null,
            selected_devices: [],
            loading: false,
            filters: {
                'last_contact': {value: null, matchMode: DATE_FILTER},
                'city': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'state': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'mac_address': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'model': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'iccid': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'fleet': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'vin': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'vehicle_model': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'vehicle_manufacturer': {value: null, matchMode: FilterMatchMode.CONTAINS}
            },
            gridData: [],
            organizations: [],
            organizationsService: null,
            loadingOperators: false,
            loadingOrgs: false,
            selectedOrg: null,
            storedChosenOptions: {}
        }
    },
    components: {
        DataTable,
        Column,
        InputText,
        MultiSelect,
        Calendar,
        Dropdown,
        ProgressBar,
        Button,
        AppButton
    },
    methods: {
        exportCsv(){
            let csvData = [];

            let tempData = JSON.parse(JSON.stringify(this.gridData));

            tempData.forEach(data => {
                data.last_contact = this.dateHourFormat(data.last_contact);
                csvData.push(data);
            });

            const CSV_FIELDS = ['last_contact', 'city', 'state', 'mac_address', 'model', 'iccid', 'fleet', 'vin', 'vehicle_manufacturer', 'vehicle_model'];
            const CSV_LABELS = ['Horário', 'Município', 'Estado', 'Serial', 'Versão', 'Assinatura', 'Frota', 'Chassi', 'Marca', 'Modelo'];

            this.exportJsonToCsv(CSV_FIELDS, CSV_LABELS, csvData, "devices");
        },
        formatICCID(val) {
            // retira os quatro primeiros digitos do iccid se estiveram presentes
            // 2 primeiros codigo da industria sempre 89
            // 2 seguintes codigo do pais, 55 no caso do brasil
            if (!val) {
                return "";
            }

            if (val.length <= 16) {
                return val;
            }
            return val.substring(4);
        },
        getOrganizations() {
            let vm = this;
            this.loadingOrgs = true;
            this.organizationsService.getAllV3('DEVICES').then((results) => {
                this.populateOrgsList(results);
                this.loadFilteredOptions();
            })
                .catch((error) => {
                    vm.$toast.add({
                        severity: 'error',
                        summary: 'Operação falhou',
                        detail: 'Não foi possível buscar organizações',
                        life: 5000
                    });
                    console.log(error)
                }).finally(() => {
                this.loadingOrgs = false;
            })
        },
        populateOrgsList(orgs) {
            orgs.forEach(data => {
                data.mac_address = this.maskMacAddress(data.mac_address);
                data.iccid = this.formatICCID(data.iccid);
            });
            this.organizations = this.orderCustomFieldByNaturalOrder(orgs, "name");
        },
        getDevices(selectedOrg) {
            let vm = this;
            this.loading = true
            this.devicesService.getDevicesByOrg(selectedOrg.id).then((response) => {
                vm.gridData = response
            }).catch(function (error) {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }
                vm.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
            }).finally(() => {
                vm.loading = false;
            })
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.selectedOrg = this.storedChosenOptions['clients'].filter(a => this.organizations.some(b => a.id === b.id))[0];
        },
        saveChosenOptionsToLocalStorage() {
            this.storedChosenOptions['clients'] = [this.selectedOrg];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
    },
    watch: {
        locale(val) {
            this.$i18n.locale = val
        },
        selectedOrg: function (val) {
            if (!val) {
                return;
            }
            this.getDevices(val)
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentOrganization',
            'isAuthenticated',
        ])
    },
}
</script>


<style lang="scss">
@import "./src/assets/styles/primevue";


@media (max-width: 1024px){
    .data-table-width{
        width: 100vw;
    }
}

@media (min-width: 1025px){
    .data-table-width{
        max-width: calc(100vw - 140px);
    }
}
</style>


<i18n>
{
    "pt-BR": {
        "devices": "Díspositivos"
    },
    "en-US": {
        "devices": "Devices"
    }
}
</i18n>
