<template>
    <div class="pb-5">
        <div class="pt-4 field-title d-flex flex-column align-items-center mb-3">
            Último contato
        </div>
        <div class="row">
            <div class="col-sm-7 col-12">
                <div class="d-flex flex-column align-items-center field-value">
                    <div>
                        <div v-if="vehicle.last_contact">
                            {{ dateHourFormat(vehicle.last_contact) }}
                        </div>
                        <div v-else>
                            N/D
                        </div>
                    </div>
                    <div>
                        <div v-if="vehicle.city && vehicle.state_code">
                            {{ vehicle.city + ' - ' + vehicle.state_code }}
                        </div>
                        <div v-else>
                            N/D
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-5 col-12">
                <div class="d-flex flex-column align-items-center mt-sm-0 mt-3">
                    <div class="field-title">
                        Horímetro
                    </div>
                    <div class="field-value">
                        <div v-if="vehicle.hour_meter">
                            {{ vehicle.hour_meter }} h
                        </div>
                        <div v-else>
                            N/D
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import dateHourFormat from "@/mixins/DateMixin";

export default {
    mixins: [dateHourFormat],
    props: {
        vehicle: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

.field-title {
    color: grey;
    font-size: large;
}

.field-value {
    color: black;
    font-weight: bold;
    font-size: large;
}

</style>
